.image {
  display: block;
  width: 100%;
  height: 100%;
  border: "2px solid black";
}

.image:hover .overlay {
  opacity: 1;
}

.image-container {
	position: relative;
	height: 100%;
}

.dark-image-container {
	position: relative;
	height: 100%;
}

.image-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	background-color: rgba(255, 174, 200, 0.6);
	color: #000000;
	font-size: 1.25em;
	font-family: "Bradley Hand", cursive;
	text-align: center;
	padding-top: 20%;
	box-sizing: border-box;
	transition: opacity 0.5s ease;

	@media screen and (max-width: 768px) {
		display: flex;
		margin: 0.2rem 0;
		padding: 0 1rem;
		font-size: 0.75em;
		cursor: pointer;
	}
}

.dark-image-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	background-color: rgba(182, 79, 200, 0.6);
	color: #ffffff;
	font-size: 1.25em;
	font-family: "Bradley Hand", cursive;
	text-align: center;
	padding-top: 20%;
	box-sizing: border-box;
	transition: opacity 0.5s ease;

	@media screen and (max-width: 768px) {
		display: flex;
		margin: 0.2rem 0;
		padding: 0 1rem;
		font-size: 0.75em;
		cursor: pointer;
	}
}

.image-container:hover .image-overlay {
	display: block;
}

.dark-image-container:hover .dark-image-overlay {
	display: block;
}